import * as React from 'react'
import Layout from '../components/layout'
import { hello } from '../styles/index.module.css'
import Seo from '../components/seo'

const IndexPage = () => {
  return (
    <Layout align="center" valign="center">
      <Seo />
      <h1 id={hello} className="display-2">Hello, I'm Andrew Kane!</h1>
      <p className="lead align-self-center">I like to create games &amp; APIs.</p>
      <p className="lead align-self-center">I enjoy solving problems, making fun experiences, and designing helpful APIs.</p>
      <p className="lead align-self-center">I believe in using open standards and cross-platform solutions.</p>
    </Layout>
  )
}

export default IndexPage
